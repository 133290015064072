<template>
  <Error v-if="page.errors.length > 0" :errors="page.errors" />
  <router-view v-else />
</template>

<script setup lang="ts">
import { onErrorCaptured } from "vue"
import Error from "#agents/layouts/Error.vue"
import { usePageStore } from "./store/page"
import { CanceledError } from "axios";

const page = usePageStore()

onErrorCaptured((err, vm, info) => {
  if (err instanceof CanceledError) {
    return false
  }

  if (page.errors.length == 0) {
    page.errors.push(err)
  }
  return false
})
</script>
